<template>
	<div class="wrap">
		<el-form ref="queryForm" :model="queryParams" size="small" tooltip-effect="dark" :inline="true" v-if="false">
			<el-form-item label="资源标题:">
				<el-input v-model="queryParams.resource_name" clearable style="width: 180px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="状态">
				<el-select v-model="queryParams.resource_start" style="width: 180px" clearable>
					<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>
		<!-- 新增按钮 -->
		<el-row :gutter="10">

			<!-- <el-col :span="1.5">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" @click="deletedata">删除</el-button>
			</el-col> -->

			<el-col :span="1.5">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="verification_info">核销</el-button>
			</el-col>

			<!-- 			<el-col :span="1.5">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleAdd">清空</el-button>
			</el-col> -->

			<!-- 			<el-col :span="1.5">
				<el-button type="warning" plain icon="el-icon-download" size="mini" @click="countDowndata">导出
				</el-button>
			</el-col> -->
			<el-col :span="1.5">
				<el-button type="warning" plain icon="el-icon-refresh-left" size="mini" @click="handshua">刷新</el-button>
			</el-col>
		</el-row>
		<!-- 表格信息 -->



		<el-table v-loading="loading" :lazy="true" :data="tableData" :header-cell-style="{ textAlign: 'center' }"
			:cell-style="{ textAlign: 'center' }" @selection-change="handleSelectionChange">
			<!-- <el-table-column type="selection" width="55"></el-table-column> -->
			<el-table-column prop="verify_id" sortable label="核销记录id" />
			<el-table-column prop="sku.title" sortable label="名称" />

			<!-- <el-table-column label="验票类型">
				<template slot-scope="scope">
					{{ scope.row.verify_type == 0 ? "默认" : '未使用' }}
				</template>
			</el-table-column> -->

			<!-- <el-table-column prop="count" label="订单券数" /> -->
			<el-table-column label="状态">
				<template slot-scope="scope">
					{{ scope.row.status == 1 ? "已核销" : '已撤销' }}
				</template>
			</el-table-column>

			<el-table-column label="是否能撤销核销">
				<template slot-scope="scope">
					{{ scope.row.can_cancel ? '是' : '否' }}
				</template>
			</el-table-column>

			<el-table-column label="验票类型">
				<template slot-scope="scope">
					{{ lk_verifyrecord_type[scope.row.verify_type].name }}
				</template>
			</el-table-column>
			<el-table-column prop="verify_time" label="核销时间" />
			<el-table-column label="操作" width="300" fixed="right">
				<template slot-scope="scope">
					<el-button v-if="scope.row.can_cancel" size="mini" type="danger"
						@click="revocation_ver(scope.row)">撤销核销</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 完整功能分页 -->
		<div class="loader-herder" v-if="total <= tableData.length">
			已经加载到底了~
		</div>

		<div class="loader-herder" v-else>
			<el-button type="primary" @click="loadnext">加载下一页</el-button>
		</div>


		<!-- <el-pagination :current-page="currentPage" :page-sizes="[15, 20, 30, 50]" :page-size="15"
			layout="->, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" /> -->
		<!-- 弹窗-添加/修改用户 -->
		<el-dialog :title="flag == true ? '添加资源' : '修改资源'" :center="true" :visible.sync="dialogFormVisible" width="50%">
			<el-form ref="form" :model="form" :rules="formRules">
				<el-form-item label="资源标题:" :label-width="formLabelWidth" prop="resource_name">
					<el-input v-model="form.resource_name" autocomplete="off" placeholder="请输入资源标题" />
				</el-form-item>

				<el-form-item label="资源类型:" :label-width="formLabelWidth" prop="resource_label">
					<el-input v-model="form.resource_label" autocomplete="off" placeholder="请输入资源类型" />
				</el-form-item>

				<el-form-item label="资源预览图:" :label-width="formLabelWidth" prop="resource_label">
					<el-input v-model="form.resource_row_img" autocomplete="off" placeholder="请输入资源预览图" />
				</el-form-item>
				<!-- 状态 -->
				<el-form-item label="资源的状态:" :label-width="formLabelWidth" prop="status">
					<el-radio-group v-model="form.resource_start">
						<el-radio :label="0">审核中</el-radio>
						<el-radio :label="1">上架</el-radio>
						<el-radio :label="2">下架</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="cancel">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="核销信息" :center="true" :visible.sync="revocation_sync" width="50%">
			<el-form ref="verification_form" :model="verification_form">
				<el-form-item label="核销门店:" :label-width="formLabelWidth" prop="poi_id">
					<el-select v-model="verification_form.poi_id" clearable placeholder="请选择门店">
						<el-option v-for="item in verification_options" :key="item.value" :label="item.name"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="核销码:" :label-width="formLabelWidth" prop="code">
					<el-input v-model="verification_form.code" autocomplete="off" placeholder="请输入核销码" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<!-- <el-button type="danger" @click="revocation_sync = false">取消</el-button> -->
				<el-button type="primary" @click="revocation_confirm">确定</el-button>
			</div>
		</el-dialog>


	</div>
</template>


<script>
// 系统用户相关的
const {
	lists_lk_check_ticket,
	// 撤销核销
	check_ticket_cancel,
	// 核销
	check_ticket_ope,
} = require("@/api/lk_check_ticket");
const { lists_lk_store } = require("@/api/lk_store")
import { lk_verifyrecord_type } from '../../util/enumeration'
export default {
	data() {
		return {
			verification_options: [],
			verification_form: {},
			revocation_sync: false,
			lk_verifyrecord_type,
			selectfileAr: [],
			showfilevisible: false,
			ishowbutton: false,
			// 进来加载表单的样式
			loading: true,
			// 顶部表单需要的数据
			queryParams: {
				// 页码
				pageNo: 1,
				// 每页显示多少条
				pageSize: 20,
			},
			// 选择器 数据
			options: [{
				id: 0,
				value: 0,
				name: '审核中'
			}, {
				id: 1,
				value: 1,
				name: '上架'
			}, {
				id: 2,
				value: 2,
				name: '下架'
			}],
			// 日期范围
			dateRange: [],
			// 表格数据
			tableData: [],
			// form 表单的lable属性 宽度
			formLabelWidth: '110px',
			// 数据总数量
			total: null,
			// 分页组件数据
			currentPage: 1,
			// 角色列表
			roleList: [],
			// 表单参数
			form: {},
			// 表单校验
			formRules: {
				topic_illustrations: [{
					required: true,
					message: '没有封面~~',
					trigger: 'blur'
				}],
				classify_remark: [{
					required: true,
					message: '请输入描述',
					trigger: 'blur'
				}],
				classify_title: [{
					required: true,
					message: '标题不能为空',
					trigger: 'blur'
				}]
			},
			// 单选框 数据
			radio: null,
			// 是否显示 添加/修改弹窗
			dialogFormVisible: false,
			// 判断是新增 还是 修改
			flag: false,
			menu_access: []
		}
	},
	watch: {
		dateRange: function () {
			this.queryParams.start_time = this.dateRange && this.dateRange[0] || undefined
			this.queryParams.end_time = this.dateRange && this.dateRange[1] || undefined
			this.handleQuery()
		},
		'queryParams.resource_name': function () {
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(() => {
				this.getRoleLists()
			}, 700);
		},
		'queryParams.resource_start': function () {
			this.getRoleLists()
		},
	},
	async created() {
		// 调用 封装好的 角色列表接口函数
		this.getRoleLists();
		this.ishowbutton = this.$is_button_power("childvideo")
	},
	methods: {

// 加载下一页
		loadnext(){
			this.getRoleLists()
		},

		//确定核销
		async revocation_confirm() {
			let check_ticket_operes = await check_ticket_ope({
				isscan: false,
				...this.verification_form,
			})
			console.log("check_ticket_ope==========>", check_ticket_operes)
			if (check_ticket_operes.code == 1) {
				this.$message.success('核销成功~')
			}

		},

		// 核销对应的信息
		async verification_info() {
			let lists_lk_storeres = await lists_lk_store({
				pageNo: 1,
				pageSize: 100
			});
			let options = []
			lists_lk_storeres.data.pois.forEach((item, index) => {
				options.push({
					id: index,
					value: item.poi.poi_id,
					name: item.poi.poi_name
				})
			})

			this.verification_options = options;
			this.revocation_sync = true;
		},

		// 撤销核销
		async revocation_ver(row) {
			this.$confirm('当前是在撤销本次核销, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.revocation_verfun(row)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		// 发起撤销
		async revocation_verfun(row) {
			const check_ticket_cancelres = await check_ticket_cancel({
				verify_id: row.verify_id,
				certificate_id: row.certificate_id
			})
			this.getRoleLists()
			console.log("check_ticket_cancelres=====>", check_ticket_cancelres)
		},

		//导出的操作
		// async countDowndata(){
		//   window.location.href = `${requrl}/api/backend.Log/export?operator=${this.queryParams.operator}&&
		//   start_time=${this.queryParams.start_time}&&end_time=${this.queryParams.end_time}&&module=${this.queryParams.module}`
		// }
		// 执行删除信息
		async deletedata() {
			this.$confirm('此操作将永久删除记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				if (!this.Arraydelete) {
					this.$message.error("请选择你要删除的信息~~")
					return;
				}
				this.handleDelete(this.Arraydelete);
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},

		// 获取删除的选项的信息
		handleSelectionChange(val) {
			let deleteArrayS = ""
			val.forEach((item, index) => {
				if (deleteArrayS == "") { //说明是空的
					deleteArrayS += item.id
				} else {
					deleteArrayS += "," + item.id
				}
			})
			this.Arraydelete = deleteArrayS
		},
		// 点击刷新按钮
		async handshua() {
			this.tableData = [];
			await this.getRoleLists();
			this.$message.success('刷新成功~')
		},

		// 封装列表接口函数
		async getRoleLists() {
			this.loading = true;
			let obj = {}
			if (this.tableData && this.tableData.length > 0) {
				obj.cursor = this.tableData[this.tableData.length - 1].cursor
			}
			const {
				data
			} = await lists_lk_check_ticket({
				...obj
			})
			this.tableData = this.tableData.concat(data.records);
			this.total = data.total;
			this.loading = false;
		},
		// 封装 根据表id查询角色
		async get_role(obj) {
			const {
				data
			} = await get_role(obj)
			this.form = {
				...this.form,
				...data
			}
		},

		// 封装 添加修改角色接口函数
		async add_or_edit_role(obj) {
			let usersaveres = await add_up_resource(obj);
			usersaveres.code == 1 ? this.$message({
				type: 'success',
				message: this.flag ? '新增成功~~' : '修改成功~~'
			}) : ''
		},
		// 封装 删除 接口函数
		async dels_topic(data) {
			// 说明是当页最后一个 且不是第一页
			if (this.tableData.length == 1 && this.queryParams.pageNo > 1) {
				this.queryParams.pageNo--
			}
			let dels_topic_res = await dels_resource(data);
			dels_topic_res.code == 1 ? this.$message({
				type: 'success',
				message: "删除成功~~"
			}) : ''
			// 调用 封装好的 角色列表接口函数
			this.getRoleLists()

			// 在删除表单最后一条数据时,自动返回上一页,但实际情况是还是删除前的页数,数据为空
			/**
			 *    this.total： 总数据条数
			 *    this.queryParams.pageSize： 每页多少条数据
			 *    this.queryParams.pageNo： 当前页码(当前是哪一页)
			 */
			const totalPage = Math.ceil((this.total - 1) / (this.queryParams.pageSize ? this.queryParams.pageSize :
				10))
			this.queryParams.pageNo = this.queryParams.pageNo > totalPage ? totalPage : this.queryParams.pageNo
			this.queryParams.pageNo = this.queryParams.pageNo < 1 ? 1 : this.queryParams.pageNo
		},

		// 顶部 新增按钮  点击触发的函数
		async handleAdd() {
			// 重置 弹窗里面的 表单数据 --开始--
			this.form = {}
			// 菜单列表
			this.menuOptions = []
			// 重置 弹窗里面的 表单数据 --结束--
			// 判断是新增
			this.flag = true
			// 显示或者隐藏 添加/修改用户 弹窗
			this.dialogFormVisible = !this.dialogFormVisible
		},

		// 点击操作里 修改按钮 触发的函数
		async handleEdit(row) {
			// 显示或者隐藏 添加/修改用户 弹窗
			this.dialogFormVisible = !this.dialogFormVisible;
			this.form = row;
		},

		// 点击操作里 启动/冻结 按钮 触发的函数
		async startUsingOrFreeze(obj) {
			let status_set = obj.identitystart == 1 ? 2 : 1;
			let statusres = await srole_status({
				id: obj.id,
				identitystart: status_set
			})
			statusres.code == 1 ? this.$message({
				type: 'success',
				message: status_set == 1 ? '解冻成功' : '冻结成功'
			}) : ''
			this.getRoleLists()
		},
		// 点击修改
		async handleMember(form) {
			this.form = JSON.parse(JSON.stringify(form));
			// 判断是新增
			this.flag = false;
			// 显示或者隐藏 添加/修改用户 弹窗
			this.dialogFormVisible = !this.dialogFormVisible
		},

		// 点击操作里 删除按钮 触发的函数
		handleDelete(id) {
			// 调用 封装好的 删除角色 接口函数
			this.dels_topic({
				ids: id
			})
		},

		// 点击查询按钮 触发的函数
		handleQuery() {
			this.getRoleLists()
		},

		// 点击重置按钮 触发的函数
		resetQuery() {
			// 重置数据
			this.queryParams = {};
			this.dateRange = [];
			// 如果重置效果也需要数据回到最初状态，则直接调用 角色列表接口函数
			this.getRoleLists()
		},

		// 弹窗里面的取消按钮
		cancel() {
			this.dialogFormVisible = !this.dialogFormVisible
			this.flag = false
			// 重置数据
			this.form = {}
			this.$nextTick(() => {
				// 移除表单校验效果
				this.$refs.form.clearValidate()
			})
		},

		// 弹窗里面的确认按钮
		async confirm() {
			console.log(this.form)
			// 表单校验
			await this.$refs.form.validate();
			// // 调用 添加/修改 角色接口函数
			await this.add_or_edit_role(this.form)
			// // 移除表单校验效果
			this.$refs.form.clearValidate()
			// 刷新页面
			await this.getRoleLists();
			this.dialogFormVisible = !this.dialogFormVisible;
			this.flag = false
			// 重置数据
			this.form = {}
		},

		// 点击切换 每页几条数据时 触发的函数
		handleSizeChange(val) {
			// 把切换的每页页数 重新赋值给 this.queryParams.pageSize
			this.queryParams.pageSize = val
			// 调用 封装好的 角色列表接口函数
			this.getRoleLists()
		},
		// 更改 前进几页 数据时 触发的函数
		handleCurrentChange(val) {
			// 把更改的页码 重新赋值给 this.queryParams.pageNo
			this.queryParams.pageNo = val
			// 调用 封装好的 角色列表接口函数
			this.getRoleLists()
		},

		// element ui 表格 格式化 函数
		formatStateCompany(row, column, cellValue) {
			if (cellValue === 1) {
				return '启用'
			} else if (cellValue === 2) {
				return '冻结'
			}
		}
	}
}
</script>

<style lang="less" scoped>
.wrap {
	margin: 20px;

	// 分页组件 距离上边的距离
	.el-pagination {
		margin-top: 20px;
	}
}

// 表头最外边框
::v-deep .el-table__header {
	margin-top: 20px;
	border: 1px solid #ebeef5;
}

.dialog-footer {
	display: flex;
	justify-content: flex-end;
}
.loader-herder {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 30px;
}
</style>